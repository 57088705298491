import { useState, useEffect } from 'react'
import { 
  BrowserRouter as Router, 
  Routes, 
  Route, 
  Navigate
} from 'react-router-dom'
import { ThemeProvider, CssBaseline } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { supabase } from './lib/supabase'
import { theme } from './theme'
import { useAuthStore } from './stores/authStore'

// Components
import Layout from './components/Layout'
import Auth from './components/Auth'
import AuthCallback from './pages/AuthCallback'
import Dashboard from './screens/Dashboard'
import Inventory from './screens/Inventory'
import ShoppingLists from './screens/ShoppingLists'
import WasteTracking from './screens/WasteTracking'
import Settings from './screens/Settings'
import Household from './screens/Household'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
})

function App() {
  const { session, setSession } = useAuthStore()

  useEffect(() => {
    // Get initial session
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    // Listen for auth changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router future={{ 
          v7_relativeSplatPath: true,
          v7_startTransition: true 
        }}>
          <Routes>
            <Route path="/auth/callback" element={<AuthCallback />} />
            {!session ? (
              <Route path="/" element={<Auth />} />
            ) : (
              <Route element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route path="inventory" element={<Inventory />} />
                <Route path="shopping-lists" element={<ShoppingLists />} />
                <Route path="waste-tracking" element={<WasteTracking />} />
                <Route path="household" element={<Household />} />
                <Route path="settings" element={<Settings />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Route>
            )}
          </Routes>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App
