import { useEffect } from 'react'
import { Grid, Card, CardContent, Typography, Box } from '@mui/material'
import {
  Warning as WarningIcon,
  ShoppingCart as ShoppingCartIcon,
  DeleteOutline as WasteIcon,
  Inventory as InventoryIcon,
} from '@mui/icons-material'
import { useQuery } from '@tanstack/react-query'
import { supabase } from '../../lib/supabase'
import { addDays, subDays } from 'date-fns'

export default function Dashboard() {
  const { data: expiringItems } = useQuery({
    queryKey: ['expiringItems'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser()
      if (!user) throw new Error('No user found')

      // First get the user's household
      const { data: userHousehold, error: householdError } = await supabase
        .from('household_members')
        .select('household_id')
        .eq('user_id', user.id)
        .single()
      
      if (householdError) throw householdError
      if (!userHousehold) throw new Error('No household found')

      const { data, error } = await supabase
        .from('food_items')
        .select('*')
        .eq('household_id', userHousehold.household_id)
        .lt('expiry_date', addDays(new Date(), 7).toISOString().split('T')[0])
        .order('expiry_date', { ascending: true })
      
      if (error) throw error
      return data
    }
  })

  const { data: shoppingListItems } = useQuery({
    queryKey: ['shoppingListItems'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser()
      if (!user) throw new Error('No user found')

      // First get the user's household
      const { data: userHousehold, error: householdError } = await supabase
        .from('household_members')
        .select('household_id')
        .eq('user_id', user.id)
        .single()
      
      if (householdError) throw householdError
      if (!userHousehold) throw new Error('No household found')

      const { data, error } = await supabase
        .from('shopping_list_items')
        .select(`
          *,
          shopping_list:shopping_lists!inner(*)
        `)
        .eq('completed', false)
        .eq('shopping_list.household_id', userHousehold.household_id)
      
      if (error) throw error
      return data
    }
  })

  const { data: wasteStats } = useQuery({
    queryKey: ['wasteStats'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser()
      if (!user) throw new Error('No user found')

      // First get the user's household
      const { data: userHousehold, error: householdError } = await supabase
        .from('household_members')
        .select('household_id')
        .eq('user_id', user.id)
        .single()
      
      if (householdError) throw householdError
      if (!userHousehold) throw new Error('No household found')

      const { data, error } = await supabase
        .from('waste_tracking')
        .select('*')
        .eq('household_id', userHousehold.household_id)
        .gte('created_at', subDays(new Date(), 30).toISOString())
      
      if (error) throw error
      return data
    }
  })

  const { data: inventoryCount } = useQuery({
    queryKey: ['inventoryCount'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser()
      if (!user) throw new Error('No user found')

      // First get the user's household
      const { data: userHousehold, error: householdError } = await supabase
        .from('household_members')
        .select('household_id')
        .eq('user_id', user.id)
        .single()
      
      if (householdError) throw householdError
      if (!userHousehold) throw new Error('No household found')

      const { count, error } = await supabase
        .from('food_items')
        .select('*', { count: 'exact', head: true })
        .eq('household_id', userHousehold.household_id)
      
      if (error) throw error
      return count
    }
  })

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Welcome to Wasteless
      </Typography>
      
      <Grid container spacing={3}>
        {/* Expiring Soon */}
        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <WarningIcon color="warning" sx={{ mr: 1 }} />
                <Typography variant="h6">Expiring Soon</Typography>
              </Box>
              <Typography variant="h4">{expiringItems?.length || 0}</Typography>
              <Typography variant="body2" color="text.secondary">
                items expiring in the next 7 days
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Shopping List Items */}
        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <ShoppingCartIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">Shopping List</Typography>
              </Box>
              <Typography variant="h4">{shoppingListItems?.length || 0}</Typography>
              <Typography variant="body2" color="text.secondary">
                items to buy
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Waste Tracking */}
        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <WasteIcon color="error" sx={{ mr: 1 }} />
                <Typography variant="h6">Waste This Month</Typography>
              </Box>
              <Typography variant="h4">{wasteStats?.length || 0}</Typography>
              <Typography variant="body2" color="text.secondary">
                items wasted in the last 30 days
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Total Inventory */}
        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <InventoryIcon color="success" sx={{ mr: 1 }} />
                <Typography variant="h6">Total Inventory</Typography>
              </Box>
              <Typography variant="h4">{inventoryCount || 0}</Typography>
              <Typography variant="body2" color="text.secondary">
                items in storage
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
