import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  CircularProgress,
  Card,
  CardMedia,
  CardContent,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import BarcodeScanner from '../components/BarcodeScanner';
import { lookupProductByBarcode } from '../services/productService';

interface InventoryItem {
  id: string;
  name: string;
  barcode?: string;
  brand?: string;
  quantity: number;
  packageSize?: string;
  expiryDate?: string;
  imageUrl?: string;
}

export default function Inventory() {
  const [items, setItems] = useState<InventoryItem[]>([]);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [newItem, setNewItem] = useState<Partial<InventoryItem>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleScan = async (barcode: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const productInfo = await lookupProductByBarcode(barcode);
      
      if (productInfo) {
        setNewItem({
          barcode,
          name: productInfo.name,
          brand: productInfo.brand,
          packageSize: productInfo.quantity,
          imageUrl: productInfo.image_url,
          quantity: 1, // Default quantity
        });
      } else {
        setNewItem({ barcode, quantity: 1 });
        setError('Product not found in database. Please enter details manually.');
      }
    } catch (err) {
      console.error('Error looking up product:', err);
      setError('Error looking up product. Please try again or enter details manually.');
      setNewItem({ barcode, quantity: 1 });
    } finally {
      setIsLoading(false);
      setIsAddDialogOpen(true);
    }
  };

  const handleAddItem = () => {
    if (newItem.name && newItem.quantity) {
      setItems([
        ...items,
        {
          id: Math.random().toString(36).substr(2, 9),
          name: newItem.name,
          barcode: newItem.barcode,
          brand: newItem.brand,
          quantity: Number(newItem.quantity),
          packageSize: newItem.packageSize,
          expiryDate: newItem.expiryDate,
          imageUrl: newItem.imageUrl,
        } as InventoryItem,
      ]);
      setNewItem({});
      setIsAddDialogOpen(false);
      setError(null);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Inventory
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <BarcodeScanner onScan={handleScan} />
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setIsAddDialogOpen(true)}
          >
            Add Item
          </Button>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Brand</TableCell>
              <TableCell>Package Size</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell>Expiry Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  {item.imageUrl && (
                    <img
                      src={item.imageUrl}
                      alt={item.name}
                      style={{ width: 50, height: 50, objectFit: 'contain' }}
                    />
                  )}
                </TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.brand || 'N/A'}</TableCell>
                <TableCell>{item.packageSize || 'N/A'}</TableCell>
                <TableCell align="right">{item.quantity}</TableCell>
                <TableCell>{item.expiryDate || 'N/A'}</TableCell>
              </TableRow>
            ))}
            {items.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No items in inventory
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog 
        open={isAddDialogOpen} 
        onClose={() => setIsAddDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add New Item</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {error && (
                <Alert severity="info" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}
              
              {newItem.imageUrl && (
                <Card sx={{ mb: 2 }}>
                  <CardMedia
                    component="img"
                    height="200"
                    image={newItem.imageUrl}
                    alt={newItem.name}
                    sx={{ objectFit: 'contain' }}
                  />
                  {newItem.brand && (
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        Brand: {newItem.brand}
                      </Typography>
                    </CardContent>
                  )}
                </Card>
              )}

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <TextField
                  label="Name"
                  value={newItem.name || ''}
                  onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
                  fullWidth
                  required
                />
                <TextField
                  label="Barcode"
                  value={newItem.barcode || ''}
                  onChange={(e) => setNewItem({ ...newItem, barcode: e.target.value })}
                  fullWidth
                />
                <TextField
                  label="Brand"
                  value={newItem.brand || ''}
                  onChange={(e) => setNewItem({ ...newItem, brand: e.target.value })}
                  fullWidth
                />
                <TextField
                  label="Package Size"
                  value={newItem.packageSize || ''}
                  onChange={(e) => setNewItem({ ...newItem, packageSize: e.target.value })}
                  fullWidth
                  placeholder="e.g., 500g, 1L"
                />
                <TextField
                  label="Quantity"
                  type="number"
                  value={newItem.quantity || ''}
                  onChange={(e) => setNewItem({ ...newItem, quantity: e.target.value })}
                  fullWidth
                  required
                />
                <TextField
                  label="Expiry Date"
                  type="date"
                  value={newItem.expiryDate || ''}
                  onChange={(e) => setNewItem({ ...newItem, expiryDate: e.target.value })}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleAddItem} 
            variant="contained"
            disabled={isLoading || !newItem.name || !newItem.quantity}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
