import { createClient } from '@supabase/supabase-js'
import { Database } from '../types/supabase'

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY
const appDomain = import.meta.env.VITE_APP_DOMAIN

// Log the exact values being used
console.log('Raw Supabase configuration:', {
  url: supabaseUrl,
  keyLength: supabaseAnonKey.length,
  key: supabaseAnonKey.substring(0, 10) + '...'
})

// Create the Supabase client with custom configuration
export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    flowType: 'pkce',
    autoRefreshToken: true,
    detectSessionInUrl: true,
    persistSession: true,
    storage: window.localStorage,
    // Override the default auth redirect URL with your domain
    redirectTo: `https://${appDomain}/auth/callback`,
  },
  global: {
    headers: {
      'x-application-name': 'wasteless',
    },
  },
})

// Log the client's internal state
console.log('Supabase client state:', {
  url: supabase.supabaseUrl,
  authUrl: supabase.auth.api?.url,
  headers: {
    ...supabase.rest.headers,
    ...supabase.auth.headers
  }
})

// Listen for auth state changes
supabase.auth.onAuthStateChange((event, session) => {
  console.log('Auth event:', event)
  console.log('Session:', session)
})
