import React, { useEffect, useRef, useState } from 'react';
import { BrowserMultiFormatReader } from '@zxing/browser';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

interface BarcodeScannerProps {
  onScan: (result: string) => void;
  buttonLabel?: string;
}

export default function BarcodeScanner({ onScan, buttonLabel = 'Scan Barcode' }: BarcodeScannerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const readerRef = useRef<BrowserMultiFormatReader | null>(null);

  useEffect(() => {
    // Initialize the barcode reader
    readerRef.current = new BrowserMultiFormatReader();

    // Cleanup on unmount
    return () => {
      if (readerRef.current) {
        readerRef.current.reset();
      }
    };
  }, []);

  const startScanning = async () => {
    if (!readerRef.current || !videoRef.current) return;

    try {
      setError(null);
      const constraints = {
        video: {
          facingMode: 'environment', // Use back camera on mobile devices
        },
      };

      // Start continuous scanning
      await readerRef.current.decodeFromConstraints(
        constraints,
        videoRef.current,
        (result, error) => {
          if (result) {
            onScan(result.getText());
            handleClose(); // Close dialog after successful scan
          }
          if (error && error.name !== 'NotFoundException') {
            console.error('Scanning error:', error);
          }
        }
      );
    } catch (err) {
      setError('Unable to access camera. Please make sure you have granted camera permissions.');
      console.error('Camera access error:', err);
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
    // Start scanning when dialog opens
    setTimeout(startScanning, 100);
  };

  const handleClose = () => {
    if (readerRef.current) {
      readerRef.current.reset();
    }
    setIsOpen(false);
    setError(null);
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<QrCodeScannerIcon />}
        onClick={handleOpen}
      >
        {buttonLabel}
      </Button>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Scan Barcode
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {error ? (
            <Typography color="error" gutterBottom>
              {error}
            </Typography>
          ) : (
            <Box sx={{ position: 'relative', width: '100%', pt: '75%' }}>
              <video
                ref={videoRef}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '80%',
                  height: '40%',
                  border: '2px solid #00ff00',
                  boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.5)',
                }}
              />
            </Box>
          )}
          <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2 }}>
            Position the barcode within the green box
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
