import React, { useState } from 'react'
import {
  Box,
  Button,
  Switch,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
  CircularProgress,
  Container,
  Paper,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { supabase } from '../../lib/supabase'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

interface UserSettings {
  id: string;
  user_id: string;
  theme: 'light' | 'dark' | 'system';
  notifications_enabled: boolean;
  notification_types: string[];
  unit_system: 'metric' | 'imperial';
  created_at: string;
  updated_at: string;
}

const NOTIFICATION_TYPES = [
  { value: 'all', label: 'All Notifications' },
  { value: 'expiry', label: 'Expiry Alerts' },
  { value: 'low_stock', label: 'Low Stock Alerts' },
  { value: 'waste', label: 'Waste Insights' },
]

const THEMES = [
  { value: 'light', label: 'Light' },
  { value: 'dark', label: 'Dark' },
  { value: 'system', label: 'System' },
]

const UNITS = [
  { value: 'metric', label: 'Metric (g, kg, ml, l)' },
  { value: 'imperial', label: 'Imperial (oz, lb, fl oz)' },
]

export default function Settings() {
  const queryClient = useQueryClient()
  const [message, setMessage] = useState<{ type: 'success' | 'error'; text: string } | null>(null)
  const [openProfileDialog, setOpenProfileDialog] = useState(false)

  // Fetch user settings
  const { data: settings, isLoading } = useQuery({
    queryKey: ['user-settings'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser()
      if (!user) throw new Error('No user found')

      const { data, error } = await supabase
        .from('user_settings')
        .select('*')
        .eq('user_id', user.id)
        .single()
        .returns<UserSettings>()

      if (error) {
        if (error.code === 'PGRST116') {
          // No settings found, create default settings
          const defaultSettings: Omit<UserSettings, 'id' | 'created_at' | 'updated_at'> = {
            user_id: user.id,
            theme: 'system',
            notifications_enabled: true,
            notification_types: ['all'],
            unit_system: 'metric'
          }

          const { data: newSettings, error: createError } = await supabase
            .from('user_settings')
            .insert(defaultSettings)
            .select()
            .single()
            .returns<UserSettings>()

          if (createError) throw createError
          return newSettings
        }
        throw error
      }
      return data
    },
    staleTime: 1000 * 60 * 5 // Consider data fresh for 5 minutes
  })

  // Update settings mutation
  const mutation = useMutation({
    mutationFn: async (newSettings: Partial<UserSettings>) => {
      const { data: { user } } = await supabase.auth.getUser()
      if (!user) throw new Error('No user found')

      const { data, error } = await supabase
        .from('user_settings')
        .update(newSettings)
        .eq('user_id', user.id)
        .select()
        .single()
        .returns<UserSettings>()

      if (error) throw error
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user-settings'] })
      setMessage({ type: 'success', text: 'Settings updated successfully' })
      setTimeout(() => setMessage(null), 3000)
    },
    onError: (error: any) => {
      setMessage({ type: 'error', text: error.message })
      setTimeout(() => setMessage(null), 3000)
    }
  })

  // Update user profile mutation
  const profileMutation = useMutation({
    mutationFn: async (profileData: { email?: string; newPassword?: string; fullName?: string }) => {
      const { data, error } = await supabase.auth.updateUser({
        email: profileData.email,
        password: profileData.newPassword,
        data: {
          full_name: profileData.fullName,
        },
      })

      if (error) throw error
      return data
    },
    onSuccess: () => {
      setOpenProfileDialog(false)
      setMessage({ type: 'success', text: 'Profile updated successfully' })
      setTimeout(() => setMessage(null), 3000)
    },
    onError: (error: any) => {
      setMessage({ type: 'error', text: error.message })
      setTimeout(() => setMessage(null), 3000)
    }
  })

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Settings
        </Typography>

        {message && (
          <Alert severity={message.type} sx={{ mb: 2 }}>
            {message.text}
          </Alert>
        )}

        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Theme
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              value={settings?.theme || 'system'}
              onChange={(e) => mutation.mutate({ theme: e.target.value as UserSettings['theme'] })}
            >
              {THEMES.map((theme) => (
                <FormControlLabel 
                  key={theme.value} 
                  value={theme.value} 
                  control={<Radio />} 
                  label={theme.label} 
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Paper>

        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Notifications
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={settings?.notifications_enabled}
                onChange={(e) => mutation.mutate({ notifications_enabled: e.target.checked })}
              />
            }
            label="Enable Notifications"
          />
          {settings?.notifications_enabled && (
            <FormGroup sx={{ mt: 2 }}>
              {NOTIFICATION_TYPES.map((type) => (
                <FormControlLabel
                  key={type.value}
                  control={
                    <Checkbox
                      checked={settings.notification_types.includes(type.value)}
                      onChange={(e) => {
                        const newTypes = e.target.checked
                          ? [...settings.notification_types, type.value]
                          : settings.notification_types.filter(t => t !== type.value)
                        mutation.mutate({ notification_types: newTypes })
                      }}
                    />
                  }
                  label={type.label}
                />
              ))}
            </FormGroup>
          )}
        </Paper>

        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Units
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              value={settings?.unit_system || 'metric'}
              onChange={(e) => mutation.mutate({ unit_system: e.target.value as UserSettings['unit_system'] })}
            >
              {UNITS.map((unit) => (
                <FormControlLabel 
                  key={unit.value} 
                  value={unit.value} 
                  control={<Radio />} 
                  label={unit.label} 
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Paper>

        <Paper sx={{ p: 3 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">
              Profile Settings
            </Typography>
            <Button
              startIcon={<EditIcon />}
              onClick={() => setOpenProfileDialog(true)}
              variant="outlined"
            >
              Edit Profile
            </Button>
          </Box>
        </Paper>

        <Dialog open={openProfileDialog} onClose={() => setOpenProfileDialog(false)}>
          <DialogTitle>Edit Profile</DialogTitle>
          <Formik
            initialValues={{
              email: '',
              currentPassword: '',
              newPassword: '',
              confirmPassword: '',
              fullName: ''
            }}
            validationSchema={Yup.object({
              email: Yup.string().email('Invalid email address'),
              currentPassword: Yup.string().min(6, 'Must be at least 6 characters'),
              newPassword: Yup.string().min(6, 'Must be at least 6 characters'),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
              fullName: Yup.string()
            })}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await profileMutation.mutateAsync({
                  email: values.email || undefined,
                  newPassword: values.newPassword || undefined,
                  fullName: values.fullName || undefined
                })
              } finally {
                setSubmitting(false)
              }
            }}
          >
            {({ isSubmitting, handleSubmit, handleChange, values, touched, errors }) => (
              <Form onSubmit={handleSubmit}>
                <DialogContent>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Email"
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Current Password"
                    name="currentPassword"
                    type="password"
                    value={values.currentPassword}
                    onChange={handleChange}
                    error={touched.currentPassword && Boolean(errors.currentPassword)}
                    helperText={touched.currentPassword && errors.currentPassword}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="New Password"
                    name="newPassword"
                    type="password"
                    value={values.newPassword}
                    onChange={handleChange}
                    error={touched.newPassword && Boolean(errors.newPassword)}
                    helperText={touched.newPassword && errors.newPassword}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Confirm Password"
                    name="confirmPassword"
                    type="password"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Full Name"
                    name="fullName"
                    value={values.fullName}
                    onChange={handleChange}
                    error={touched.fullName && Boolean(errors.fullName)}
                    helperText={touched.fullName && errors.fullName}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpenProfileDialog(false)}>
                    Cancel
                  </Button>
                  <Button type="submit" disabled={isSubmitting}>
                    Save Changes
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </Box>
    </Container>
  )
}
