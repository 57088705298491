import { useState } from 'react'
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Snackbar,
  Alert,
} from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { supabase } from '../../lib/supabase'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'

export default function WasteTracking() {
  const [openDialog, setOpenDialog] = useState(false)
  const [notification, setNotification] = useState<{ type: 'success' | 'error', message: string } | null>(null)
  const queryClient = useQueryClient()

  // Fetch waste tracking data
  const { data: wasteItems, isLoading } = useQuery({
    queryKey: ['waste-tracking'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('waste_tracking')
        .select('*')
        .order('created_at', { ascending: false })

      if (error) throw error
      return data
    }
  })

  // Fetch waste statistics
  const { data: wasteStats } = useQuery({
    queryKey: ['waste-statistics'],
    queryFn: async () => {
      // Get statistics for the last 30 days
      const endDate = new Date()
      const startDate = new Date()
      startDate.setDate(startDate.getDate() - 30)

      const { data, error } = await supabase
        .rpc('get_waste_statistics', {
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString()
        })

      if (error) throw error
      return data
    }
  })

  // Add waste item mutation
  const addWasteMutation = useMutation({
    mutationFn: async (item: any) => {
      const { data, error } = await supabase
        .from('waste_tracking')
        .insert(item)
        .select()
        .single()

      if (error) throw error
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['waste-tracking'] })
      queryClient.invalidateQueries({ queryKey: ['waste-statistics'] })
      setOpenDialog(false)
      setNotification({
        type: 'success',
        message: 'Waste item recorded successfully!'
      })
    },
    onError: (error: any) => {
      setNotification({
        type: 'error',
        message: error.message || 'Failed to record waste item'
      })
    }
  })

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const formData = new FormData(event.target as HTMLFormElement)
    const item = {
      item_name: formData.get('name'),
      quantity: parseFloat(formData.get('quantity') as string),
      unit: formData.get('unit'),
      reason: formData.get('reason'),
      disposal_date: new Date().toISOString(),
    }
    addWasteMutation.mutate(item)
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Waste Tracking</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
        >
          Record Waste
        </Button>
      </Box>

      {/* Statistics Section */}
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Monthly Waste Statistics</Typography>
              <Box height={300}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={wasteStats}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="total_quantity" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Common Waste Reasons</Typography>
              <Box height={300}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={wasteStats?.reason_breakdown || []}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="reason" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Recent Waste Items */}
      <Typography variant="h6" gutterBottom>Recent Waste Items</Typography>
      <Grid container spacing={3}>
        {isLoading ? (
          <Grid item xs={12}>
            <Typography>Loading...</Typography>
          </Grid>
        ) : (
          wasteItems?.map((item: any) => (
            <Grid item xs={12} sm={6} md={4} key={item.id}>
              <Card>
                <CardContent>
                  <Typography variant="h6">{item.item_name}</Typography>
                  <Typography color="textSecondary">
                    {new Date(item.disposal_date).toLocaleDateString()}
                  </Typography>
                  <Typography>
                    Quantity: {item.quantity} {item.unit}
                  </Typography>
                  <Typography>
                    Reason: {item.reason}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        )}
      </Grid>

      {/* Add Waste Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Record Waste Item</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="Item Name"
              fullWidth
              required
            />
            <TextField
              margin="dense"
              name="quantity"
              label="Quantity"
              type="number"
              fullWidth
              required
            />
            <TextField
              margin="dense"
              name="unit"
              label="Unit"
              select
              fullWidth
              required
              defaultValue=""
            >
              <MenuItem value="grams">Grams</MenuItem>
              <MenuItem value="kilograms">Kilograms</MenuItem>
              <MenuItem value="pieces">Pieces</MenuItem>
              <MenuItem value="servings">Servings</MenuItem>
            </TextField>
            <TextField
              margin="dense"
              name="reason"
              label="Reason"
              select
              fullWidth
              required
              defaultValue=""
            >
              <MenuItem value="expired">Expired</MenuItem>
              <MenuItem value="spoiled">Spoiled</MenuItem>
              <MenuItem value="leftover">Leftover</MenuItem>
              <MenuItem value="overcooked">Overcooked</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            <Button type="submit" variant="contained">Save</Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Notifications */}
      <Snackbar
        open={!!notification}
        autoHideDuration={6000}
        onClose={() => setNotification(null)}
      >
        <Alert
          onClose={() => setNotification(null)}
          severity={notification?.type}
          sx={{ width: '100%' }}
        >
          {notification?.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}
