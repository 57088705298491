import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useNavigationPerformance = () => {
  const location = useLocation();

  useEffect(() => {
    const navigationStart = performance.now();
    const navigationMark = `nav-${location.pathname}-${navigationStart}`;
    
    // Mark the start of navigation
    performance.mark(navigationMark);
    console.log('🚀 Starting navigation to:', location.pathname);

    // Use requestAnimationFrame to measure after render
    requestAnimationFrame(() => {
      const navigationEnd = performance.now();
      const totalDuration = Math.round(navigationEnd - navigationStart);
      
      console.log('📊 Navigation Metrics:', {
        route: location.pathname,
        navigationDuration: `${totalDuration}ms`,
        timestamp: new Date().toISOString(),
        isInitialLoad: !document.hidden,
        routeDepth: location.pathname.split('/').filter(Boolean).length
      });

      // Clear the performance mark
      performance.clearMarks(navigationMark);
    });

    return () => {
      performance.clearMarks(navigationMark);
    };
  }, [location.pathname]);
};
