import { useState } from 'react'
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Alert,
  Chip,
  Grid,
} from '@mui/material'
import {
  Person as PersonIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Mail as MailIcon,
} from '@mui/icons-material'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { supabase } from '../../lib/supabase'

const MEMBER_ROLES = [
  { value: 'admin', label: 'Admin' },
  { value: 'member', label: 'Member' },
  { value: 'viewer', label: 'Viewer' },
]

export default function Household() {
  const [openDialog, setOpenDialog] = useState(false)
  const [openInviteDialog, setOpenInviteDialog] = useState(false)
  const [selectedMember, setSelectedMember] = useState<any>(null)
  const [message, setMessage] = useState<{ type: 'success' | 'error'; text: string } | null>(null)
  const queryClient = useQueryClient()

  // Fetch household and members
  const { data: household, isLoading } = useQuery({
    queryKey: ['household'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser()
      if (!user) throw new Error('No user found')

      const { data, error } = await supabase
        .from('households')
        .select(`
          *,
          household_members (
            id,
            user_id,
            role,
            joined_at,
            users (
              id,
              email,
              raw_user_meta_data
            )
          )
        `)
        .eq('id', user.household_id)
        .single()

      if (error) throw error
      return data
    }
  })

  // Update member role mutation
  const updateMemberMutation = useMutation({
    mutationFn: async ({ memberId, role }: { memberId: string; role: string }) => {
      const { data, error } = await supabase
        .from('household_members')
        .update({ role })
        .eq('id', memberId)
        .select()
        .single()

      if (error) throw error
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['household'] })
      handleCloseDialog()
      setMessage({ type: 'success', text: 'Member role updated successfully' })
      setTimeout(() => setMessage(null), 3000)
    },
    onError: (error: any) => {
      setMessage({ type: 'error', text: error.message || 'Failed to update member role' })
      setTimeout(() => setMessage(null), 3000)
    }
  })

  // Remove member mutation
  const removeMemberMutation = useMutation({
    mutationFn: async (memberId: string) => {
      const { error } = await supabase
        .from('household_members')
        .delete()
        .eq('id', memberId)

      if (error) throw error
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['household'] })
      setMessage({ type: 'success', text: 'Member removed successfully' })
      setTimeout(() => setMessage(null), 3000)
    },
    onError: (error: any) => {
      setMessage({ type: 'error', text: error.message || 'Failed to remove member' })
      setTimeout(() => setMessage(null), 3000)
    }
  })

  // Invite member mutation
  const inviteMemberMutation = useMutation({
    mutationFn: async (email: string) => {
      const { data, error } = await supabase.functions.invoke('invite-household-member', {
        body: { email }
      })

      if (error) throw error
      return data
    },
    onSuccess: () => {
      setOpenInviteDialog(false)
      setMessage({ type: 'success', text: 'Invitation sent successfully' })
      setTimeout(() => setMessage(null), 3000)
    },
    onError: (error: any) => {
      setMessage({ type: 'error', text: error.message || 'Failed to send invitation' })
      setTimeout(() => setMessage(null), 3000)
    }
  })

  const handleOpenDialog = (member: any) => {
    setSelectedMember(member)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setSelectedMember(null)
    setOpenDialog(false)
  }

  const handleUpdateRole = (event: React.FormEvent) => {
    event.preventDefault()
    const formData = new FormData(event.target as HTMLFormElement)
    if (selectedMember) {
      updateMemberMutation.mutate({
        memberId: selectedMember.id,
        role: formData.get('role') as string
      })
    }
  }

  const handleRemoveMember = (memberId: string) => {
    if (confirm('Are you sure you want to remove this member?')) {
      removeMemberMutation.mutate(memberId)
    }
  }

  const handleInviteMember = (event: React.FormEvent) => {
    event.preventDefault()
    const formData = new FormData(event.target as HTMLFormElement)
    const email = formData.get('email') as string
    inviteMemberMutation.mutate(email)
  }

  if (isLoading) {
    return (
      <Box>
        <Typography>Loading...</Typography>
      </Box>
    )
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Household</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenInviteDialog(true)}
        >
          Invite Member
        </Button>
      </Box>

      {message && (
        <Alert severity={message.type} sx={{ mb: 3 }}>
          {message.text}
        </Alert>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Members
              </Typography>
              <List>
                {household?.household_members?.map((member: any) => (
                  <ListItem key={member.id}>
                    <ListItemAvatar>
                      <Avatar>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={member.users.raw_user_meta_data?.full_name || member.users.email}
                      secondary={
                        <Box>
                          <Chip
                            size="small"
                            label={member.role}
                            color={member.role === 'admin' ? 'primary' : 'default'}
                            sx={{ mr: 1 }}
                          />
                          <Typography variant="caption" color="textSecondary">
                            Joined {new Date(member.joined_at).toLocaleDateString()}
                          </Typography>
                        </Box>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={() => handleOpenDialog(member)}
                        sx={{ mr: 1 }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        onClick={() => handleRemoveMember(member.id)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Update Role Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <form onSubmit={handleUpdateRole}>
          <DialogTitle>Update Member Role</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              name="role"
              label="Role"
              select
              fullWidth
              defaultValue={selectedMember?.role}
              required
            >
              {MEMBER_ROLES.map((role) => (
                <MenuItem key={role.value} value={role.value}>
                  {role.label}
                </MenuItem>
              ))}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button type="submit" variant="contained">Save</Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Invite Member Dialog */}
      <Dialog open={openInviteDialog} onClose={() => setOpenInviteDialog(false)}>
        <form onSubmit={handleInviteMember}>
          <DialogTitle>Invite Member</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="email"
              label="Email Address"
              type="email"
              fullWidth
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenInviteDialog(false)}>Cancel</Button>
            <Button type="submit" variant="contained">Send Invitation</Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  )
}
