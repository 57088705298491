export const config = {
  supabase: {
    url: import.meta.env.VITE_SUPABASE_URL,
    anonKey: import.meta.env.VITE_SUPABASE_ANON_KEY,
    site_url: import.meta.env.VITE_APP_DOMAIN ? `https://${import.meta.env.VITE_APP_DOMAIN}` : 'http://localhost:5173',
    redirect_url: import.meta.env.VITE_APP_DOMAIN ? `https://${import.meta.env.VITE_APP_DOMAIN}/auth/callback` : 'http://localhost:5173/auth/callback'
  },
  app: {
    name: 'Wasteless',
    domain: import.meta.env.VITE_APP_DOMAIN || 'localhost:5173'
  }
}
