interface ProductInfo {
  name: string;
  brand?: string;
  image_url?: string;
  quantity?: string;
  categories?: string[];
  ingredients?: string[];
  nutriments?: {
    [key: string]: number;
  };
}

export async function lookupProductByBarcode(barcode: string): Promise<ProductInfo | null> {
  try {
    const response = await fetch(
      `https://world.openfoodfacts.org/api/v2/product/${barcode}.json`
    );
    const data = await response.json();

    if (!data.status === 1 || !data.product) {
      console.log('Product not found in database');
      return null;
    }

    const product = data.product;
    return {
      name: product.product_name || product.generic_name || '',
      brand: product.brands,
      image_url: product.image_url,
      quantity: product.quantity,
      categories: product.categories_tags,
      ingredients: product.ingredients_tags,
      nutriments: product.nutriments,
    };
  } catch (error) {
    console.error('Error looking up product:', error);
    return null;
  }
}
