import { useState } from 'react'
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Fab,
  Card,
  CardContent,
  Grid,
  Snackbar,
  Alert,
} from '@mui/material'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Share as ShareIcon,
} from '@mui/icons-material'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { supabase } from '../../lib/supabase'

export default function ShoppingLists() {
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedList, setSelectedList] = useState<any>(null)
  const [openItemDialog, setOpenItemDialog] = useState(false)
  const [notification, setNotification] = useState<{ type: 'success' | 'error', message: string } | null>(null)
  const queryClient = useQueryClient()

  // Fetch shopping lists
  const { data: lists, isLoading } = useQuery({
    queryKey: ['shopping-lists'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('shopping_lists')
        .select(`
          *,
          shopping_list_items (
            id,
            name,
            quantity,
            unit,
            completed
          )
        `)
        .order('created_at', { ascending: false })

      if (error) throw error
      return data
    }
  })

  // Add/Edit list mutation
  const listMutation = useMutation({
    mutationFn: async (list: any) => {
      const { data, error } = await supabase
        .from('shopping_lists')
        .upsert(list)
        .select()
        .single()

      if (error) throw error
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['shopping-lists'] })
      setOpenDialog(false)
      setSelectedList(null)
      setNotification({
        type: 'success',
        message: 'Shopping list saved successfully!'
      })
    },
    onError: (error: any) => {
      setNotification({
        type: 'error',
        message: error.message || 'Failed to save shopping list'
      })
    }
  })

  // Delete list mutation
  const deleteListMutation = useMutation({
    mutationFn: async (id: number) => {
      const { error } = await supabase
        .from('shopping_lists')
        .delete()
        .eq('id', id)

      if (error) throw error
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['shopping-lists'] })
      setNotification({
        type: 'success',
        message: 'Shopping list deleted successfully!'
      })
    },
    onError: (error: any) => {
      setNotification({
        type: 'error',
        message: error.message || 'Failed to delete shopping list'
      })
    }
  })

  // Add/Edit item mutation
  const itemMutation = useMutation({
    mutationFn: async (item: any) => {
      const { data, error } = await supabase
        .from('shopping_list_items')
        .upsert(item)
        .select()
        .single()

      if (error) throw error
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['shopping-lists'] })
      setOpenItemDialog(false)
      setNotification({
        type: 'success',
        message: 'Item saved successfully!'
      })
    },
    onError: (error: any) => {
      setNotification({
        type: 'error',
        message: error.message || 'Failed to save item'
      })
    }
  })

  // Toggle item completion mutation
  const toggleItemMutation = useMutation({
    mutationFn: async ({ id, completed }: { id: number; completed: boolean }) => {
      const { error } = await supabase
        .from('shopping_list_items')
        .update({ completed })
        .eq('id', id)

      if (error) throw error
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['shopping-lists'] })
    }
  })

  const handleCreateList = () => {
    setSelectedList(null)
    setOpenDialog(true)
  }

  const handleEditList = (list: any) => {
    setSelectedList(list)
    setOpenDialog(true)
  }

  const handleDeleteList = (id: number) => {
    deleteListMutation.mutate(id)
  }

  const handleSaveList = (event: React.FormEvent) => {
    event.preventDefault()
    const formData = new FormData(event.target as HTMLFormElement)
    const list = {
      name: formData.get('name'),
      ...(selectedList && { id: selectedList.id })
    }
    listMutation.mutate(list)
  }

  const handleAddItem = (listId: number) => {
    setSelectedList({ id: listId })
    setOpenItemDialog(true)
  }

  const handleSaveItem = (event: React.FormEvent) => {
    event.preventDefault()
    const formData = new FormData(event.target as HTMLFormElement)
    const item = {
      name: formData.get('name'),
      quantity: formData.get('quantity'),
      unit: formData.get('unit'),
      shopping_list_id: selectedList.id,
      completed: false
    }
    itemMutation.mutate(item)
  }

  const handleToggleItem = (id: number, completed: boolean) => {
    toggleItemMutation.mutate({ id, completed: !completed })
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Shopping Lists</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleCreateList}
        >
          Create List
        </Button>
      </Box>

      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Grid container spacing={3}>
          {lists?.map((list: any) => (
            <Grid item xs={12} sm={6} md={4} key={list.id}>
              <Card>
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                    <Typography variant="h6">{list.name}</Typography>
                    <Box>
                      <IconButton onClick={() => handleEditList(list)} size="small">
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteList(list.id)} size="small" color="error">
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <List>
                    {list.shopping_list_items?.map((item: any) => (
                      <ListItem key={item.id} dense>
                        <Checkbox
                          edge="start"
                          checked={item.completed}
                          onChange={() => handleToggleItem(item.id, item.completed)}
                        />
                        <ListItemText
                          primary={item.name}
                          secondary={`${item.quantity} ${item.unit}`}
                          sx={{
                            textDecoration: item.completed ? 'line-through' : 'none'
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => handleAddItem(list.id)}
                    fullWidth
                  >
                    Add Item
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {/* List Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <form onSubmit={handleSaveList}>
          <DialogTitle>
            {selectedList ? 'Edit Shopping List' : 'Create Shopping List'}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="List Name"
              fullWidth
              defaultValue={selectedList?.name}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            <Button type="submit" variant="contained">Save</Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Item Dialog */}
      <Dialog open={openItemDialog} onClose={() => setOpenItemDialog(false)}>
        <form onSubmit={handleSaveItem}>
          <DialogTitle>Add Item</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="Item Name"
              fullWidth
              required
            />
            <TextField
              margin="dense"
              name="quantity"
              label="Quantity"
              type="number"
              fullWidth
              required
            />
            <TextField
              margin="dense"
              name="unit"
              label="Unit"
              fullWidth
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenItemDialog(false)}>Cancel</Button>
            <Button type="submit" variant="contained">Save</Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Notifications */}
      <Snackbar
        open={!!notification}
        autoHideDuration={6000}
        onClose={() => setNotification(null)}
      >
        <Alert
          onClose={() => setNotification(null)}
          severity={notification?.type}
          sx={{ width: '100%' }}
        >
          {notification?.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}
